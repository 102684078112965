import React from "react";

const Helpdesk = () => {
  return (
    <div className="text-center  h-screen bg-green-400 border-2 p-4  shadow-2xl">
      <div>Contact</div>
      <div>Instagram</div>
      <div>Twitter</div>
    </div>
  );
};

export default Helpdesk;
